import io from "socket.io-client";

const environment = process.env.NODE_ENV;

// * 현재 연결된 와이파이 ip로 설정
// export const socket = io.connect(
//   // environment === "development" ? "192.168.0.50" : "https://plussol.wplusedu.co.kr",
//   environment === "development" ? "localhost" : "https://www.withplus.live",
//   // environment === "development" ? "192.168.0.154" : "https://www.withplus.live",
//   // environment === "development" ? "172.30.1.8" : "https://www.withplus.live",
//   // environment === "development" ? "172.30.1.36" : "https://www.withplus.live",
//   // environment === "development" ? "192.168.0.51" : "https://plussol.wplusedu.co.kr",

//   { cors: { origin: "*", reconnection: true } }
// );

export const socket = io.connect(
  // environment === "development" ? "192.168.0.50" : "https://plussol.wplusedu.co.kr",
  // "3.37.197.42",
  "https://wwwt.withplus.live",
  //집에서 할 때 안되면 위에꺼 주석 처리하고 해보자
  // "http://192.168.0.16:3000",
  // environment === "development" ? "192.168.0.154" : "https://www.withplus.live",
  // environment === "development" ? "172.30.1.8" : "https://www.withplus.live",
  // environment === "development" ? "172.30.1.36" : "https://www.withplus.live",
  // environment === "development" ? "192.168.0.51" : "https://plussol.wplusedu.co.kr",

  { cors: { origin: "*", reconnection: true } }
);
