import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { IconButton, Modal, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GET_API_CODE } from "utils/api/Hexagram";
import QRCode from "qrcode.react";
import ManagerPageLayout from "Layout/ManagerPageLayout";
import StickyHeadTable from "components/StickeyHeadTable";
import ReactDragDrop from "components/DragDropFile";
import DeleteIcon from "@mui/icons-material/Delete";

import { useContext } from "react";
import { ManagerContext, ManagerProvider } from "../contexts/ManagerContext";
import axios from "axios";
import FileAlreadyUploadTable from "components/FileAlreadyUploadTable";
import { ToolTip } from "./styles";

// GET_USERS 함수 정의
export const GET_USERS = (gameId) => {
  return new Promise((resolve, reject) => {
    axios
      .get("/live-hexagram/users", { params: { gameId } })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            p: 3,
            boxSizing: "border-box",
            height: "100%",
            width: "100%",
            position: "absolute",
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// 페이지 전체 레이아웃
const Index = () => {
  return (
    <ManagerProvider>
      <ManagerPageLayout>
        {{
          name: "Live Hexagram",
          ContentBox: <Content />,
          QRCodeBox: <QRCodeBox />,
        }}
      </ManagerPageLayout>
    </ManagerProvider>
  );
};

// 컨텐츠 컴포넌트 (왼쪽 박스)
const Content = () => {
  const { gameId } = useParams();
  const { socket } = useContext(ManagerContext);

  const [list, setList] = useState([]);
  const [value, setValue] = useState(0);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [tokens, setTokens] = useState(0);
  const [token, setToken] = useState(0);

  useEffect(() => {
    if (!socket) return;

    socket.emit("getUsersUnderTest", { gameId }, (res) => {
      setList(res);
    });
    socket.emit("getToken", { gameId }, (res) => {
      // console.log("토큰", res);
      setToken(res);
    });
  }, [socket]);

  useEffect(() => {
    if (!socket) return;

    socket.on("updateUsersUnderTest", (data) => {
      setList(data);
    });
    socket.on("getToken", (data) => {
      console.log("토큰", data);
      setToken(data);
    });

    return () => {
      socket.off("updateUsersUnderTest");
      socket.off("getToken");
    };
  });

  useEffect(() => {
    if (!gameId) return;

    if (value === 2) {
      // 파일 업로드 탭이 선택될 때만 GET_USERS 실행
      setLoading(true);
      GET_USERS(gameId)
        .then((res) => {
          setUsers(res.data.users || []);
        })
        .catch((error) => {
          console.error("에러", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [gameId, value]);

  const getUsers = (gameId) => {
    GET_USERS(gameId)
      .then((res) => {
        setUsers(res.data.users || []);
      })
      .catch((error) => {
        console.error("에러", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ height: "100%", overflow: "auto", display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "80px",
          boxShadow: "0px 3px 6px #00000028",
          bgcolor: "bisque",
          "> p": {
            font: "bold 45px Pretendard",
          },
        }}
      >
        <p>Hexagram Test</p>
      </Box>
      <Box sx={{ width: "100%", flex: 1, display: "flex", flexDirection: "column" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            sx={{ "& button": { fontWeight: 600 } }}
          >
            <Tab label={`진행중 (${list.length})`} {...a11yProps(0)} />
            <Tab label="결과" {...a11yProps(1)} />
            <Tab label="핵사그램 진단 요청하기" {...a11yProps(2)} />
          </Tabs>
        </Box>

        <Box sx={{ flex: 1, position: "relative" }}>
          <CustomTabPanel value={value} index={0}>
            <TestingTable list={list} token={token} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <StickyHeadTable />
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                marginTop: "12px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "6px",
                }}
              >
                <Box
                  sx={{
                    width: "16px",
                    height: "16px",
                    borderRadius: "4px",
                    backgroundColor: "#FF968A",
                  }}
                ></Box>
                <Typography
                  sx={{
                    font: "600 16px Pretendard",
                  }}
                >
                  기본 타입
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "6px",
                  marginLeft: "14px",
                  paddingRight: "14px",
                }}
              >
                <Box
                  sx={{
                    width: "16px",
                    height: "16px",
                    borderRadius: "4px",
                    backgroundColor: "#55CBCD",
                  }}
                ></Box>
                <Typography
                  sx={{
                    font: "600 16px Pretendard",
                  }}
                >
                  2차 타입
                </Typography>
              </Box>
            </Box>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            {loading ? (
              <p>Loading...</p>
            ) : users.length > 0 ? (
              <FileAlreadyUploadTable users={users} getUsers={getUsers} />
            ) : (
              <ReactDragDrop users={users} />
            )}
          </CustomTabPanel>
        </Box>
      </Box>
    </Box>
  );
};

// QR코드 컴포넌트 (오른쪽 박스)
const QRCodeBox = () => {
  const [baseURL, setBaseURL] = useState("");
  const [accessCode, setAccessCode] = useState("123456");
  const [modalOpened, setModalOpened] = useState(false);
  const { gameId } = useParams();

  const handleQRcodeModal = () => {
    setModalOpened(!modalOpened);
  };

  useEffect(() => {
    GET_API_CODE(gameId)
      .then((res) => {
        const code = res.data.code;
        if (typeof code === "string") {
          setAccessCode(code);
        } else {
          setAccessCode(String(code));
        }
      })
      .catch((error) => {
        console.error("Error fetching access code:", error);
      });
  }, [gameId]);

  return (
    <>
      <Box
        sx={{
          margin: "0 auto",
          padding: "3px",
          borderRadius: "10px",
          height: "350px",
          boxSizing: "border-box",
          cursor: "pointer",
          transition: "all 0.3s ease-in-out",
          "&:hover": { transform: "scale(1.05)" },
        }}
        onClick={handleQRcodeModal}
      >
        <QRCode
          style={{
            width: "80%",
            height: "80%",
            margin: "27px 0 0 40px",
          }}
          value={baseURL + `/hexagram/user/${gameId}`}
          renderAs="svg"
        />
      </Box>
      <Box>
        <Typography
          sx={{
            display: "flex",
            justifyContent: "center",
            font: "bold 28px Pretendard",
            color: "#B43F3F",
            marginBottom: "10px",
          }}
        >
          접속 코드
        </Typography>
        <Typography
          sx={{
            display: "flex",
            justifyContent: "center",
            font: "bold 40px NotoSansKR-Medium",
            color: "#B43F3F",
          }}
        >
          {accessCode.slice(0, 3) + " " + accessCode.slice(3)}
        </Typography>
      </Box>

      <Modal
        open={modalOpened}
        onClose={handleQRcodeModal}
        sx={{
          "& :focus-visible": {
            outline: "none ",
          },
        }}
        disablePortal
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "40%",
            cursor: "pointer",
          }}
          onClick={handleQRcodeModal}
        >
          <QRCode
            renderAs="svg"
            value={baseURL + `/hexagram/user/${gameId}`}
            style={{ width: "100%", height: "100%" }}
          />
        </Box>
      </Modal>
    </>
  );
};

const TestingTable = ({ list, token }) => {
  const { gameId } = useParams();
  const { socket } = useContext(ManagerContext);

  const removeUser = (e) => {
    if (window.confirm("해당 유저를 삭제하시겠습니까?")) {
      socket.emit("removeUserUnderTest", { gameId, userId: e.currentTarget.id });
    }
  };
  return (
    <Box sx={{ height: "100%", overflow: "auto" }}>
      <Box sx={{ position: "relative" }}>
        <Typography
          sx={{
            font: "bold 20px Pretendard",
            color: "#777",
            position: "absolute",
            left: 0,
            top: "50%",
            transform: "translateY(-50%)",
          }}
        >
          남은 토큰 수: {token}
        </Typography>

        <Typography
          sx={{
            font: "bold 28px Pretendard",
            color: "#333",
            textAlign: "center",
            mb: "20px",
          }}
        >
          지금 핵사그램 진단 진행중인 유저
        </Typography>
      </Box>

      {list.map((user) => (
        <Box
          key={user.userId}
          sx={{
            display: "flex",
            // alignItems: "center",
            gap: "5px",
            mb: "20px",
          }}
        >
          <Box sx={{ border: "1px solid #ccc", p: "10px", borderRadius: "10px" }}>
            <Typography
              sx={{
                font: "bold 20px Pretendard",
                mb: "5px",
                "&:before": {
                  content: '"• 이름: "',
                  color: "#aaa",
                  marginRight: "5px",
                },
              }}
            >
              {user.name}
            </Typography>

            <Typography
              sx={{
                font: "bold 20px Pretendard",
                mb: "5px",
                "&:before": {
                  content: '"• 소속: "',
                  color: "#aaa",
                  marginRight: "5px",
                },
              }}
            >
              {user.affiliation}
            </Typography>

            <Typography sx={{ textAlign: "right", color: "#999", fontWeight: "500" }}>
              진단지 진행중...
            </Typography>
          </Box>

          <Box
            sx={{
              flex: 1,
              borderBottom: "3px dashed #aaa",
              color: "#fff",
            }}
          >
            1
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              pr: "10px",
            }}
          >
            <ToolTip title="해당 유저 삭제하기" arrow>
              <IconButton
                onClick={removeUser}
                id={user.userId}
                sx={{
                  border: "2px solid #777",
                  boxShadow: "0px 3px 6px #00000028",
                  "&:active": {
                    boxShadow: "none",
                    transform: "translateY(3px)",
                  },
                }}
              >
                <DeleteIcon fontSize="30px" />
              </IconButton>
            </ToolTip>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default Index;
