import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Box, Grid, IconButton, Tooltip, Typography, Button } from "@mui/material";

import Title from "components/Title";

import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import HelpIcon from "@mui/icons-material/Help";

import logo from "assets/images/wLiveLogo(Red).png";

// import list from "views/LiveQuiz/template/temp";

const EditPageLayout = (props) => {
  const { children, action } = props;
  const { id } = useParams();

  // // 테스트용
  // const [idx, setIdx] = useState(0);

  // const first = () => {
  //   window.location.href = `/features/quiz/edit/${list[0]}`;
  // };

  // const next = () => {
  //   const idx = list.indexOf(id);

  //   if (idx === list.length - 1) {
  //     alert("마지막 페이지입니다.");
  //     return;
  //   }

  //   window.location.href = `/features/quiz/edit/${list[idx + 1]}`;

  //   // console.log(list);
  //   // console.log(id);
  // };

  // useEffect(() => {
  //   let idx = list.indexOf(id);

  //   setIdx(idx);
  // }, []);
  // // 테스트용 end

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        bgcolor: "#f5f5f5",
      }}
    >
      <Box
        sx={{
          // minWidth: "1200px",
          // p: "10px",
          // boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
          minWidth: "1400px",
          maxWidth: "1920px",
          maxHeight: "1080px",

          // overflow: "hidden",
        }}
      >
        {/* <Grid className="justify-content-center" container>
        <Grid
          item
          xs={12}
          sx={{ p: "10px", display: "flex", flexDirection: "column", bgcolor: "red" }}
        > */}
        {/* <Title {...props} /> */}
        <Box
          sx={{
            bgcolor: "#fff",
            position: "relative",
            display: "flex",
            justifyContent: "center",
            boxShadow: "0 3px 6px #00000029",
            borderRadius: "10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              p: "12.5px",
              opacity: "0.75",
            }}
          >
            <img
              src={logo}
              alt="logo"
              style={{
                width: "30px",
                height: "30px",
              }}
            />
            <Typography sx={{ font: "700 20px Pretendard" }}>
              Withplus Live - 라이브 퀴즈
            </Typography>
          </Box>

          <Box
            sx={{
              position: "absolute",
              top: "50%",
              right: "10px",
              transform: "translateY(-50%)",
              display: "flex",
              gap: "10px",
              alignItems: "center",
            }}
          >
            {/* 테스트용 */}
            {/* {localStorage.getItem("UUID_ADM") && (
              <>
                <Box>
                  {idx + 1} / {list.length}
                </Box>
                <Button variant="contained" onClick={first}>
                  처음으로
                </Button>
                <Button variant="contained" onClick={next}>
                  다음
                </Button>
              </>
            )} */}
            {/* 테스트용 */}

            <Tooltip title="도움말" arrow>
              <IconButton
                onClick={() => {
                  window.open(
                    "https://withplus-live.notion.site/Live-Quiz-d7632fc9964a4eb7afb024887706264b",
                    "_blank",
                    "width=600, height=800"
                  );
                }}
              >
                <HelpIcon fontSize="large" sx={{ color: "#999" }} />
              </IconButton>
            </Tooltip>

            <Button
              variant="contained"
              sx={{
                display: "flex",
                gap: "5px",
                height: "auto",
                font: "700 18px Pretendard",
                p: "5px",
              }}
              onClick={action}
            >
              <SaveIcon fontSize="large" />
              저장하기
            </Button>

            {/* <Tooltip title="나가기" arrow>
              <IconButton onClick={() => window.close()}>
                <CloseIcon fontSize="large" />
              </IconButton>
            </Tooltip> */}
          </Box>
        </Box>

        <Box
          className="paper"
          children={children.content}
          sx={{ flex: 1, m: "10px", boxSizing: "border-box", overflow: "hidden" }}
        />
        {/* </Grid> */}
        {/* </Grid> */}
      </Box>
    </Box>
  );
};

export default EditPageLayout;
