import Axios from "axios";

const axios = Axios.create({
  baseURL: "https://test.poll.withplus.live",
});

export const getPollList = (token, callback) => {
  axios.get("/list", { params: { token } }).then((response) => {
    callback(response);
  });
};

export const addPoll = (auth, callback) => {
  axios.post("/add", null, { params: { auth } }).then((res) => {
    callback(res);
  });
};

export const removePoll = (deletePollList, callback) => {
  axios.post("/delete", null, { params: { polls: deletePollList } }).then((res) => {
    callback(res);
  });
};

export const duplicatePoll = (pollId, callback) => {
  axios.post("/duplicate", null, { params: { pollId: pollId } }).then((res) => {
    callback(res);
  });
};

export const resetPoll = (pollId, callback) => {
  axios.post("/reset", null, { params: { pollId: pollId } }).then((res) => {
    callback(res);
  });
};

export const editPoll = (id, title, slideList, callback) => {
  axios.post("/edit", { id: id, title: title, slideList: slideList }).then((res) => {
    callback(res);
  });
};

export const getPollData = (id, callback) => {
  axios.get("/data", { params: { id } }).then((res) => {
    callback(res);
  });
};
