import React, { createContext, useEffect, useRef, useState } from "react";
import io from "socket.io-client";
import { useNavigate, useParams } from "react-router-dom";
// import { useCookies } from "react-cookie";
import { v4 as uuidv4 } from "uuid";

// dev
// import { internalIpV4 } from "internal-ip";

import { SocketProvider, useSocket } from "contexts/Socket";

const ManagerContext = createContext({
  socket: null,
  isSocketDisconnected: false,
  code: "123456",
  name: "",
  explain: "",
  userURL: "",
  postModalOpened: false,
  targetSection: null,
  modifyTarget: null,

  handlePostModal: () => {},
  handleModifyTarget: () => {},
});

const ManagerProvider = ({ children }) => {
  const { gameId } = useParams();
  const socket = useSocket(process.env.REACT_APP_LIVE_BOARD_SERVER_URL);
  // const [cookies, setCookie] = useCookies();

  const [isSocketDisconnected, setSocketDisconnected] = useState(false);
  const [isSortSectionModalOpened, setIsSortSectionModalOpened] = useState(false);
  const [isCardModalOpened, setIsCardModalOpened] = useState(false);

  const [code, setCode] = useState("123456");
  const [name, setName] = useState("");
  const [explain, setExplain] = useState("");
  const [list, setList] = useState([]);
  const [layout, setLayout] = useState({});
  const [sections, setSections] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const [postModalOpened, setPostModalOpened] = useState(false);
  const [targetSection, setTargetSection] = useState(null);
  const [modifyTarget, setModifyTarget] = useState(null);
  const [userURL, setUserURL] = useState(`https://test.www.withplus.live/board/user/${gameId}`);

  const handleSortSectionModal = () => {
    setIsSortSectionModalOpened((prev) => !prev);
  };

  const handleCardModal = (e) => {
    let itemId = e.currentTarget.getAttribute("item-id");

    if (!itemId) {
      setSelectedCard(null);
    } else {
      let item = list.find((item) => item.id === itemId);
      setSelectedCard(item);
    }

    setIsCardModalOpened((prev) => !prev);
  };

  const handlePostModal = (e) => {
    if (Boolean(e?.currentTarget.id)) {
      setTargetSection(e.currentTarget.id);
    } else {
      setTargetSection(null);
    }

    setPostModalOpened((prev) => !prev);
  };

  const handleModifyTarget = (item) => {
    setModifyTarget(item);
  };

  useEffect(() => {
    socket.emit("getCode", { gameId }, (res) => {
      let { success, code, message } = res;

      if (success) {
        setCode(code.code + "");
      } else {
        alert(message);
        window.close();
      }
    });

    socket.emit("getBoard", { gameId }, (res) => {
      if (res.success) {
        setName(res.board.name);
        setExplain(res.board.explain);
        setLayout(res.board.layout);
        setSections(res.board.sections);
        // console.log(res.board);
      } else {
        alert(res.message);
        window.close();
      }
    });

    socket.emit("getList", { gameId });
  }, []);

  useEffect(() => {
    if (!selectedCard) return;

    list.forEach((item) => {
      if (item.id === selectedCard.id) {
        setSelectedCard(item);
      }
    });
  }, [list]);

  useEffect(() => {
    if (!postModalOpened) setModifyTarget(null);
  }, [postModalOpened]);

  useEffect(() => {
    socket.on("connect", () => {
      console.log("connect");
      setSocketDisconnected(false);
      let uid = localStorage.getItem("uuid");

      if (!uid) {
        uid = uuidv4();
        localStorage.setItem("uuid", uid);
      }

      socket.emit("joinRoom", { gameId, uid });
      socket.emit("getBoard", { gameId }, (res) => {
        if (res.success) {
          setName(res.board.name);
          setExplain(res.board.explain);
          setLayout(res.board.layout);
          setSections(res.board.sections);
          // console.log(res.board);
        }
      });
    });

    socket.on("disconnect", () => {
      console.log("disconnect");
      setSocketDisconnected(true);
    });

    socket.on("getList", (data) => {
      setList(data.list);
    });

    socket.on("changeTitle", (data) => {
      setName(data.name);
    });

    socket.on("changeExplain", (data) => {
      setExplain(data.explain);
    });

    socket.on("changeLayout", (data) => {
      setLayout(data.layout);
    });

    socket.on("addGroup", (data) => {
      setSections(data.sections);
    });

    socket.on("sortSections", (data) => {
      setSections(data.sections);
    });

    socket.on("deleteSection", (data) => {
      setSections(data.sections);
    });

    socket.on("changeSectionName", (data) => {
      setSections(data.sections);
    });

    socket.on("postBoard", (data) => {
      setList(data.list);
    });

    socket.on("handleLike", (data) => {
      setList(data.board.list);
    });

    socket.on("addComment", (data) => {
      setList(data.board.list);

      if (selectedCard && selectedCard.id === data.post.id) {
        setSelectedCard(data.post);
      }
    });

    socket.on("deletePost", (data) => {
      setList(data.board.list);
    });

    return () => {
      socket.off("connect");
      socket.off("disconnect");
      socket.off("getList");
      socket.off("changeTitle");
      socket.off("changeExplain");
      socket.off("changeLayout");
      socket.off("addGroup");
      socket.off("sortSections");
      socket.off("deleteSection");
      socket.off("changeSectionName");
      socket.off("postBoard");
      socket.off("handleLike");
      socket.off("addComment");
      socket.off("deletePost");
    };
  });

  const value = {
    socket,
    isSocketDisconnected,
    code,
    name,
    explain,
    list,
    userURL,
    layout,
    sections,
    isSortSectionModalOpened,
    isCardModalOpened,
    selectedCard,
    postModalOpened,
    targetSection,
    modifyTarget,

    handleSortSectionModal,
    setList,
    handleCardModal,
    setSelectedCard,
    handlePostModal,
    handleModifyTarget,
  };

  return <ManagerContext.Provider value={value}>{children}</ManagerContext.Provider>;
};

export { ManagerContext, ManagerProvider };
