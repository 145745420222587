import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import QRCode from "qrcode.react";
import { useCookies } from "react-cookie";

import { Typography, Grid, Modal, Box } from "@mui/material";

// import PersonIcon from "@mui/icons-material/Person";
import PeopleIcon from "@mui/icons-material/People";

import { styles as styles } from "./styles";

// import { ManagerContext, ManagerProvider } from "../contexts/ManagerContext";
import { ManagerContext } from "../contexts/ManagerContext";

import { CustomButton as Button } from "components/Button";
import { SnackBar } from "components/SnackBar";

import ManagerPageLayout from "../Layout/ManagerPageLayout";

// import Avartar from "assets/images/Avartar";
// import logo from "assets/images/wliveLogo.png";
// import postIt from "assets/images/LiveTap/post-it.png";
import background from "assets/images/LiveTap_new/background_start.webp";
// import teamCharacter from "assets/images/LiveTap/teamCharacter";
import teamCharacter from "assets/images/LiveTap_new/Character";
// import maskingTapeImg from "assets/images/LiveTap/maskingTape.png";

import { DELETE_USER, GET_API_CODE } from "utils/api/Tap";

const WaitingRoom = () => {
  return (
    // <ManagerProvider>
    <ManagerPageLayout title={"Live Tap"} codeOpen={true}>
      {{
        name: "Live Tap",
        ContentBox: <Content />,
        QRCodeBox: <QRCodeBox />,
      }}
    </ManagerPageLayout>
    // </ManagerProvider>
  );
};

const Content = () => {
  const boxRef = useRef(null);

  const { tap, setTap, socket } = useContext(ManagerContext);
  const [snackBarOpened, setSnackBarOpened] = useState(false);
  const [snackBarSeverity, setSnackBarSeverity] = useState("success");
  const [snackBarText, setSnackBarText] = useState("");
  const [isStart, setIsStart] = useState(false);
  const [boxStyles, setBoxStyles] = useState({});
  const [cookies] = useCookies();
  const { gameId } = useParams();
  const [teamCount, setTeamCount] = useState(1);
  const navigate = useNavigate();

  // const borderColor = ["rgb(106, 84, 130)"];
  const borderColor = ["purple", "brown", "gray", "orange"];

  const handleSnackBar = () => {
    setSnackBarOpened(false);
  };

  const gotoGame = () => {
    setTimeout(() => {
      socket.emit("goToGame", { gameId });
      navigate(`/tap/manager/${gameId}/game`);
    }, 250);
  };

  // 0819 소켓 확인하면서 유저가 돌아가기 버튼을 눌렀는지 확인
  useEffect(() => {
    if (!socket) return;
    const handleUpdatedTap = (updatedTapData) => {
      setTap(updatedTapData);
    };
    socket.on("updatedTap", handleUpdatedTap);
    return () => {
      socket.off("updatedTap", handleUpdatedTap);
    };
  }, [socket, setTap]);

  /**
   * 0819 유저 클릭하면 삭제하는 기능
   * @param {string} uid 유저 아이디
   * @param {string} tapId  라이브 탭 아이디
   */

  const handleDeleteUser = (uid, tapId) => {
    if (window.confirm("해당 유저를 삭제하겠습니까?")) {
      DELETE_USER(uid, tapId)
        .then((res) => {
          if (res.success) {
            socket.emit("deleteUser", { uid, tapId });

            // 상태 업데이트 및 스낵바 표시
            setSnackBarOpened(true);
            setSnackBarSeverity("success");
            setSnackBarText("삭제가 완료되었습니다.");

            // UI 업데이트
            const updatedTeamData = tap.teamData.map((team) => {
              return {
                ...team,
                teamMembers: team.teamMembers.filter((member) => member.uid !== uid),
              };
            });
            setTap((prevTap) => ({
              ...prevTap,
              teamData: updatedTeamData,
            }));
          } else {
            setSnackBarOpened(true);
            setSnackBarSeverity("error");
            setSnackBarText("삭제에 실패하였습니다. 다시 시도해주세요.");
          }
        })
        .catch((err) => {
          console.error(err);
          alert("유저 삭제 중 오류가 발생했습니다.");
        });
    }
  };

  useEffect(() => {
    if (!tap.teamData) return;
    if (tap.teamData.length >= 2) setTeamCount(tap.teamData.length);
  }, [tap]);

  // 시작하기 버튼 활성화
  useEffect(() => {
    let startButtonEnabled = false;

    if (tap.gameMode === "team") {
      startButtonEnabled = tap.teamData.every((team) => team.teamMembers.length > 0);
    } else if (tap.gameMode === "individual") {
      startButtonEnabled = tap.individualData.length > 1;
    }

    setIsStart(startButtonEnabled);
  }, [tap]);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      const boxWidth = entries[0].contentRect.width;
      const boxHeight = entries[0].contentRect.height;

      if (boxWidth / boxHeight > 16 / 9) {
        // console.log("가로가 더 길어요");
        setBoxStyles({
          height: "100%",
        });
      } else {
        // console.log("세로가 더 길어요");
        setBoxStyles({
          width: "100%",
        });
      }
    });

    if (boxRef.current) observer.observe(boxRef.current);

    return () => {
      boxRef.current && observer.unobserve(boxRef.current);
    };
  }, []);

  return (
    <Box
      ref={boxRef}
      sx={{
        height: "100%",
        overflow: "hidden",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      <Box
        sx={{
          ...boxStyles,
          aspectRatio: "16/9",
          backgroundImage: `url(${background})`,
          backgroundSize: "cover",
          position: "relative",
        }}
      >
        {tap.gameMode === "team" && (
          <>
            <Grid
              container
              sx={{
                // bgcolor: "#ff000050",
                height: tap.teamData?.length > 2 ? "100%" : "50%",
              }}
            >
              {tap.teamData.map((team, index) => {
                return (
                  <Grid
                    item
                    xs={6}
                    key={index}
                    sx={{
                      // bgcolor: "#ffff0050",
                      p: "10px",
                      display: "flex",
                      justifyContent: index % 2 === 1 ? "flex-end" : "flex-start",
                      alignItems: index >= 2 ? "flex-end" : "flex-start",
                      boxSizing: "border-box",
                    }}
                  >
                    <Box
                      sx={{
                        border: "5px solid #333",
                        borderColor: borderColor[index] || "#333",
                        width: "90%",
                        height: "90%",
                        bgcolor: "rgba(255, 255, 255, 0.3)",
                        backdropFilter: "blur(8px)",
                        padding: "10px",
                        boxSizing: "border-box",
                        borderRadius: "8px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Box sx={{ height: "15%", position: "relative" }}>
                        <Box
                          sx={{
                            position: "absolute",
                            width: "20%",
                            transform: "translate(-50%, -50%)",
                            top: "25%",
                            left: "5%",
                          }}
                        >
                          <img src={teamCharacter[index]} alt="teamCharacter" />
                        </Box>

                        <Box
                          sx={{
                            position: "absolute",
                            left: "50%",
                            transform: "translateX(-50%)",
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <Typography
                            sx={{
                              font: "900 30px Pretendard",
                              color: "#333",
                              textShadow: "2px 2px 2px #00000088",
                              WebkitTextStroke: "1px #fff",
                            }}
                          >
                            {index + 1}팀
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            position: "absolute",
                            display: "flex",
                            left: "56%",
                            top: "50%",
                            alignItems: "center",
                            transform: "translateY(-50%)",
                          }}
                        >
                          <PeopleIcon sx={styles.intro.participant_box.icon} />
                          <Typography sx={{ fontSize: "20px", fontWeight: "600" }}>
                            {team.teamMembers.length}
                          </Typography>
                        </Box>
                      </Box>

                      <Box sx={{ flex: 1, position: "relative" }}>
                        <Box
                          sx={{
                            // bgcolor: "#ff000050",
                            overflow: "auto",
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                            overflow: "auto",
                          }}
                        >
                          <Grid container rowGap={"20px"}>
                            {team.teamMembers.map((member, index) => (
                              <Grid
                                item
                                xs={6}
                                key={index}
                                sx={{
                                  width: "auto",
                                  // bgcolor: "#fff",
                                  display: "flex",
                                  gap: "10px",
                                  px: "5px",
                                  py: "5px",
                                  cursor: "pointer",
                                  alignItems: "center",
                                }}
                                onClick={() => handleDeleteUser(member.uid, tap._id)} // 클릭 시 삭제 요청 처리
                              >
                                <Typography
                                  sx={{
                                    fontSize: "32px",
                                    fontFamily: "Prentendard",
                                    fontWeight: 700,
                                    color: "#333",
                                  }}
                                >
                                  {index + 1}.
                                </Typography>

                                <Typography sx={styles.intro.participant_box.text} noWrap>
                                  {member.name}
                                </Typography>
                              </Grid>
                            ))}
                          </Grid>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>

            <Button
              onClick={gotoGame}
              disabled={!isStart} // isStart가 false이면 버튼 비활성화
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                font: "700 26px Pretendard",
                fontSize: "clamp(20px, 1.67vw, 30px)",
                boxShadow: "4px 4px 4px #73B9C9",
                "&:active": { boxShadow: "none", transform: "translate(-50%, calc(-50% + 4px))" },
                bgcolor: isStart ? "#d6e2fb" : "#f0f0f0", // 비활성화 상태일 때 색상 변경
                color: isStart ? "#50586C" : "#a1a1a1", // 비활성화 상태일 때 텍스트 색상 변경
                "&:hover": isStart ? { bgcolor: "#c6d2eb" } : {}, // 비활성화 상태에서는 hover 효과 없음
                padding: "10px 20px",
              }}
            >
              시작하기
            </Button>
          </>
        )}
      </Box>
      {/* {tap.gameMode === "team" && (
        <Box
          sx={{
            ...boxStyles,
            aspectRatio: "16/9",
            backgroundImage: `url(${background})`,
            backgroundSize: "cover",
          }}
        >
          <Grid
            container
            sx={{
              height: "100%",
              bgcolor: "#ff000050",
            }}
          >
            {tap.teamData.map((team, index) => (
              <Grid item xs={6} key={index}>
                <Box
                  sx={{
                    height: "100%",
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      // backgroundImage: `url(${postIt})`,
                      backgroundSize: "100%",
                      backgroundRepeat: "no-repeat",
                      height: teamCount === 2 ? null : "100%",
                      width: teamCount === 2 ? "100%" : null,
                      aspectRatio: "2956/1892",
                      right: index % 2 === 0 ? null : 0,
                      left: index % 2 === 0 ? 0 : null,
                      top: "50%",
                      transform: "translateY(-50%)",
                      filter: "drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.25))",
                    }}
                  >
                    <Box
                      sx={{
                        position: "relative",
                        height: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          position: "absolute",
                          left: "42%",
                          top: "8%",
                          transform: "rotate(-2.6deg)",
                          font: "700 40px JGaegujaengyi-Medium-KO",
                          color: "#333",
                          textShadow: "2px 2px 2px #00000088",
                        }}
                      >
                        {index + 1}팀
                      </Box>

                      <Box
                        sx={{
                          // bgcolor: "#ff000050",
                          position: "absolute",
                          width: "20%",
                          top: "0%",
                          left: "7.5%",
                          transform: "rotate(-6deg)",
                        }}
                      >
                        <img src={teamCharacter[index]} alt="teamCharacter" />
                      </Box>

                      <Box
                        sx={{
                          // bgcolor: "#ff000050",
                          position: "absolute",
                          display: "flex",
                          left: "73%",
                          top: "7.5%",
                          transform: "rotate(-2deg)",
                          alignItems: "center",
                        }}
                      >
                        <PeopleIcon sx={styles.intro.participant_box.icon} />
                        <Typography sx={{ fontSize: "20px", fontWeight: "600" }}>
                          {team.teamMembers.length}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          position: "absolute",
                          top: "22%",
                          left: "12%",
                          // bgcolor: "#ff000050",
                          width: "75%",
                          height: "71%",
                          transform: "rotate(-2.2deg)",
                          overflow: "auto",
                          p: "5px",
                          boxSizing: "border-box",
                        }}
                      >
                        <Grid container rowGap={"20px"}>
                          {team.teamMembers.map((member, index) => (
                            <Grid
                              item
                              xs={6}
                              key={index}
                              sx={{
                                width: "auto",
                                // bgcolor: "#fff",
                                display: "flex",
                                gap: "10px",
                                px: "5px",
                                py: "5px",
                                cursor: "pointer",
                                alignItems: "center",
                              }}
                              onClick={() => handleDeleteUser(member.uid, tap._id)} // 클릭 시 삭제 요청 처리
                            >
                              <Typography
                                sx={{
                                  fontSize: "32px",
                                  fontFamily: "JGaegujaengyi-Medium-KO",
                                  fontWeight: 700,
                                  color: "#333",
                                }}
                              >
                                {index + 1}.
                              </Typography>

                              <Typography sx={styles.intro.participant_box.text} noWrap>
                                {member.name}
                              </Typography>
                            </Grid>
                          ))}
                        </Grid>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>

          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: teamCount === 2 ? "48.5%" : "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <Button
              onClick={gotoGame}
              disabled={!isStart} // isStart가 false이면 버튼 비활성화
              sx={{
                font: "700 26px Pretendard",
                fontSize: "clamp(16.25px, 1.354vw, 22px)",
                borderRadius: "50%",
                width: "clamp(75px, 6.25vw, 100px)",
                height: "clamp(75px, 6.25vw, 100px)",
                bgcolor: isStart ? "#d6e2fb" : "#f0f0f0", // 비활성화 상태일 때 색상 변경
                color: isStart ? "#50586C" : "#a1a1a1", // 비활성화 상태일 때 텍스트 색상 변경
                "&:hover": isStart ? { bgcolor: "#c6d2eb" } : {}, // 비활성화 상태에서는 hover 효과 없음
              }}
            >
              시작하기
            </Button>
          </Box>
        </Box>
      )} */}

      <SnackBar
        open={snackBarOpened}
        onClose={handleSnackBar}
        severity={snackBarSeverity}
        text={snackBarText}
      />
    </Box>
  );
};

const QRCodeBox = () => {
  const [baseURL, setBaseURL] = useState("https://wwwt.withplus.live");
  const [accessCode, setAccessCode] = useState("123456");
  const [modalOpened, setModalOpened] = useState(false);
  const { gameId } = useParams();

  const handleQRcodeModal = () => {
    setModalOpened(!modalOpened);
  };

  useEffect(() => {
    GET_API_CODE(gameId)
      .then((res) => {
        const code = res.data.code;
        if (typeof code === "string") {
          setAccessCode(code);
        } else {
          setAccessCode(String(code));
        }
      })
      .catch((error) => {
        console.error("Error fetching access code:", error);
      });
  }, [gameId]);

  return (
    <>
      <Box
        sx={{
          margin: "0 auto",
          padding: "3px",
          borderRadius: "10px",
          height: "350px",
          boxSizing: "border-box",
          cursor: "pointer",
          transition: "all 0.3s ease-in-out",
          "&:hover": { transform: "scale(1.05)" },
        }}
        onClick={handleQRcodeModal}
      >
        <QRCode
          style={{
            width: "80%",
            height: "80%",
            margin: "27px 0 0 40px",
          }}
          value={baseURL + `/tap/user/${gameId}`}
          renderAs="svg"
        />
      </Box>
      <Box>
        <Typography
          sx={{
            display: "flex",
            justifyContent: "center",
            font: "bold 28px Pretendard",
            color: "#B43F3F",
            marginBottom: "10px",
          }}
        >
          접속 코드
        </Typography>
        <Typography
          sx={{
            display: "flex",
            justifyContent: "center",
            font: "bold 40px NotoSansKR-Medium",
            color: "#B43F3F",
          }}
        >
          {accessCode.slice(0, 3) + " " + accessCode.slice(3)}
        </Typography>
      </Box>

      <Modal
        open={modalOpened}
        onClose={handleQRcodeModal}
        sx={{
          "& :focus-visible": {
            outline: "none",
          },
        }}
        disablePortal
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "40%",
            cursor: "pointer",
          }}
          onClick={handleQRcodeModal}
        >
          <QRCode
            renderAs="svg"
            value={baseURL + `/tap/user/${gameId}`}
            style={{ width: "100%", height: "100%" }}
          />
        </Box>
      </Modal>
    </>
  );
};

export default WaitingRoom;
