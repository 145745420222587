import AWS from "aws-sdk";

const ACCESS_KEY = process.env.REACT_APP_AWS_ACCESS_KEY;
const SECRET_ACCESS_KEY = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
const REGION = "ap-northeast-2";
const BUCKET_NAME = "withplus-live-test-bucket";
const FOLDER_NAME = "quiz-media/";

AWS.config.update({
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
});

const s3 = new AWS.S3({
  region: REGION,
  params: {
    Bucket: BUCKET_NAME,
  },
});

export const uploadFile = (file, callback) => {
  const params = {
    // Key: "quiz-media/IMG_8158",
    // Key: "quiz-media/IMG_8158.png",
    Key: "quiz-media/" + file.name,
    Body: file.url,
  };

  // console.log(params);
  //   return;

  s3.upload(params, (err, data) => {
    if (err) {
      // console.log(err);
      return;
    }

    // console.log(data);
    callback(data);
  });
};

// 이미지 불러오기
export const getImage = (file, callback) => {
  const params = {
    Key: file,
  };

  s3.getObject(params, (err, data) => {
    if (err) {
      // console.log(err);
      return;
    }

    // console.log(data);
    callback(data);
  });
};

export const downloadFile = (file, callback) => {
  return new Promise((resolve, reject) => {
    const params = {
      Key: file,
    };

    s3.getObject(params, (err, data) => {
      if (err) {
        // console.log(err);
        reject(err);
      }

      // // console.log(data);
      resolve(data);
    });
  });
  //   const params = {
  //     Key: file,
  //   };

  //   s3.getObject(params, (err, data) => {
  //     if (err) {
  //       // console.log(err);
  //       return;
  //     }

  //     // console.log(data);
  //     callback(data);
  //   });
};

// 버킷 내의 모든 파일 받아오기
export const listFiles = (callback) => {
  const params = {
    Bucket: BUCKET_NAME,
    Prefix: FOLDER_NAME,
  };

  s3.listObjects(params, (err, data) => {
    if (err) {
      // console.log(err);
      return;
    }

    // console.log(data);
    callback(data);
  });
};

export const deleteFile = (file, callback) => {
  const params = {
    Key: file,
  };

  s3.deleteObject(params, (err, data) => {
    if (err) {
      // console.log(err);
      return;
    }

    // console.log(data);
    callback(data);
  });
};

export default { uploadFile, getImage, downloadFile, listFiles, deleteFile };
