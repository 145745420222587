import React, { useEffect, useState } from "react";
import axios from "axios";
import { Route, Routes, useNavigate, useSearchParams } from "react-router-dom";
import { useCookies } from "react-cookie";

import {
  Typography,
  Button,
  Tooltip,
  Box,
  Container,
  Checkbox,
  Modal,
  CircularProgress,
} from "@mui/material";

import AnnouncementIcon from "@mui/icons-material/Announcement";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CheckIcon from "@mui/icons-material/Check";
import GetAppIcon from "@mui/icons-material/GetApp";

import { /*CancelSubscriptionModal,*/ TermOfUseModal } from "components/Modal";
import { BillingHistoryTable } from "components/Table";
import cardLogo from "assets/images/cardLogo";
import icChipImg from "assets/images/icChip.svg";
import { Textfield } from "components/Textfield";

const index = ({ user }) => {
  return (
    <Routes>
      <Route path="" element={<Subscribe user={user} />} />
      <Route path="/change-payment-info" element={<ChangePaymentInfo user={user} />} />
      <Route path="/cancel" element={<Cancel user={user} />} />
      <Route path="/payment-immediately" element={<ImmediatePayment user={user} />} />
    </Routes>
  );
};

const Subscribe = ({ user }) => {
  const navigate = useNavigate();
  const [cookies] = useCookies();
  const now = new Date(new Date().setHours(0, 0, 0, 0));

  const [status, setStatus] = useState(null);
  const [plan, setPlan] = useState(null);
  const [ExpiredAt, setExpiredAt] = useState(null);
  const [userPlan, setUserPlan] = useState(null);
  const [startedAt, setStartedAt] = useState(null);
  const [paymentInfo, setPaymentInfo] = useState(null);
  const [nextPaymentAt, setNextPaymentAt] = useState(null);
  const [billings, setBillings] = useState(null);
  const [group, setGroup] = useState(null);
  const [modalOpened, setModalOpened] = useState(false);

  const handleModal = () => {
    setModalOpened((prev) => !prev);
  };

  useEffect(() => {
    if (!user) return;

    const ExpiredAt = new Date(user.ExpiredAt);
    ExpiredAt.setHours(0, 0, 0, 0);

    if (user.plan === "free") {
      if (ExpiredAt < now) {
        setPlan("FREE");
      } else {
        setPlan("무료 평가판");
      }
    } else if (user.plan === "basic") {
      if (user.planType === "period") {
        const startedAt = new Date(user.StartedAt);
        const ExpiredAt = new Date(user.ExpiredAt);

        const period = Math.floor((ExpiredAt - startedAt) / (1000 * 60 * 60 * 24 * 30));

        setPlan(`베이직 (기간 선택) - ${period}개월`);
      } else {
        if (user.period === "monthly") {
          setPlan("베이직 구독 (월간 결제)");
        } else {
          setPlan("베이직 구독 (연간 결제)");
        }
      }
    } else {
      setPlan(
        user.plan === "basic_monthly" ? "베이식 구독 (월간 결제)" : "베이식 구독 (연간 결제)"
      );
    }

    setStatus(user.status);

    setExpiredAt(ExpiredAt);
    setUserPlan(user.plan);
    setStartedAt(new Date(user.StartedAt));
    setPaymentInfo(user.paymentInfo);

    if (user.group) {
      setGroup(user.group);
    }

    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    if (!paymentInfo) return;

    setNextPaymentAt(new Date(paymentInfo.nextPaymentAt));
  }, [paymentInfo]);

  useEffect(() => {
    axios
      .get("/api/billingHistory", { params: { auth: cookies.auth } })
      .then((res) => {
        const billings = res.data.billings.filter((billing) => billing.approvedAt !== undefined);
        setBillings(billings.reverse());
      })
      .catch((err) => {
        console.log(err);
      });

    // eslint-disable-next-line
  }, []);

  return (
    user && (
      <div className="DashBoard">
        <div className="plan-container">
          <Typography
            fontSize="2rem"
            textAlign="left"
            color="primary"
            fontFamily="Pretendard"
            fontWeight="bold"
          >
            나의 이용중인 상품 플랜
          </Typography>
          <div className="plan">
            <div className="plan-info">
              <Typography
                color="primary"
                fontSize="1.5rem"
                textAlign="left"
                fontFamily="Pretendard"
                fontWeight="bold"
              >
                {group && group.isAccepted && "[단체 계정 구독 모델] - "}
                {plan}
              </Typography>
              {startedAt && ExpiredAt && (
                <Typography color="#797979" fontSize="1rem" fontFamily="Noto Sans" textAlign="left">
                  {/* 기간 설정 함수로 */}

                  {userPlan === "free" && ExpiredAt < now
                    ? `${startedAt.getFullYear()} - ${("0" + (startedAt.getMonth() + 1)).slice(
                        -2
                      )} - ${("0" + startedAt.getDate()).slice(-2)} ~ `
                    : `${startedAt.getFullYear()} - ${("0" + (startedAt.getMonth() + 1)).slice(
                        -2
                      )} - ${("0" + startedAt.getDate()).slice(
                        -2
                      )} ~ ${ExpiredAt.getFullYear()} - ${("0" + (ExpiredAt.getMonth() + 1)).slice(
                        -2
                      )} - ${("0" + ExpiredAt.getDate()).slice(-2)}`}
                </Typography>
              )}
              <div style={{ width: "60%", marginTop: "2%", display: "flex", gap: "10px" }}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => navigate("/plan")}
                  sx={{ font: "bold 15px Pretendard" }}
                  disabled={group && group.isAccepted}
                >
                  {group && group.isAccepted
                    ? "단체 계정 구독 모델은 변경 불가능합니다."
                    : "상품 플랜 변경하기 "}
                  {status === "Group" && "(단체 계정용)"}
                </Button>

                {userPlan === "free" && (
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{
                      font: "700 15px Pretendard",
                      bgcolor: "#2c6974",
                      "&:hover": { bgcolor: "#0c4954" },
                    }}
                    onClick={handleModal}
                  >
                    이벤트 쿠폰 등록하기
                  </Button>
                )}
              </div>
            </div>
            <div className="line" />
            <div className="plan-info">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  pr: "20px",
                }}
              >
                <Typography
                  color="primary"
                  textAlign="left"
                  fontFamily="Noto Sans"
                  fontWeight="700"
                  fontSize="1.5rem"
                >
                  다음 결제 정보
                </Typography>

                {paymentInfo && nextPaymentAt < now && (
                  <Tooltip
                    title={
                      <p
                        style={{
                          font: "14px NotoSansKR-Medium",
                          wordBreak: "keep-all",
                          textAlign: "center",
                          lineHeight: "1",
                        }}
                      >
                        지금 바로 클릭하여 결제해서 구독 모델을 사용할게요.
                      </p>
                    }
                    arrow
                    placement="top"
                  >
                    <Button
                      variant="contained"
                      sx={{
                        position: "relative",
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                        border: "1px solid #ff0000",
                        borderRadius: "5px",
                        padding: "5px",
                        bgcolor: "red",
                        "&:hover": { bgcolor: "#ee0000" },
                      }}
                      onClick={() => navigate("payment-immediately")}
                    >
                      <AnnouncementIcon sx={{ color: "white" }} />
                      <span style={{ color: "white", font: "16px NotoSansKR-Medium" }}>
                        결제가 완료되지 않았습니다.
                      </span>
                    </Button>
                  </Tooltip>
                )}
              </Box>

              {paymentInfo && (
                <>
                  <Typography
                    textAlign="left"
                    mt="5px"
                    ml="10px"
                    color="#878787"
                    fontFamily="Noto Sans"
                  >
                    {`- 구독 플랜 : ${
                      paymentInfo.plan === "monthly" ? "베이식 (월간 결제)" : "베이식 (연간 결제)"
                    }`}
                  </Typography>

                  <Typography
                    textAlign="left"
                    mt="5px"
                    ml="10px"
                    color="#878787"
                    fontFamily="Noto Sans"
                  >
                    {`- 결제 금액 : ${(paymentInfo.finalPrice * 1).toLocaleString("ko-KR")}원`}
                  </Typography>

                  {nextPaymentAt && (
                    <Typography
                      textAlign="left"
                      mt="5px"
                      ml="10px"
                      color="#878787"
                      fontFamily="Noto Sans"
                    >
                      {`- 결제일 : ${nextPaymentAt.getFullYear()} - ${(
                        "0" +
                        (nextPaymentAt.getMonth() + 1)
                      ).slice(-2)} - ${("0" + nextPaymentAt.getDate()).slice(-2)}`}
                    </Typography>
                  )}

                  <Box sx={{ display: "flex", gap: "1rem", pr: "1rem", mt: "1rem" }}>
                    <Button
                      variant="contained"
                      sx={{
                        font: "bold 14px NotoSansKR-Regular",
                        bgcolor: "#ababab",
                        "&:hover": { bgcolor: "#9a9a9a" },
                      }}
                      onClick={() => navigate("change-payment-info")}
                    >
                      결제 수단 변경하기
                    </Button>

                    <Button
                      variant="outlined"
                      sx={{
                        font: "bold 14px NotoSansKR-Regular",
                        border: "2px solid",
                        "&:hover": { border: "2px solid" },
                      }}
                      // onClick={handleCancelSubscriptionModal}
                      onClick={() => navigate("cancel")}
                    >
                      구독 취소하기
                    </Button>
                  </Box>

                  {/* <CancelSubscriptionModal
                    open={cancelSubscriptionModalOpened}
                    handleModal={handleCancelSubscriptionModal}
                  /> */}
                </>
              )}
            </div>
          </div>

          <div>
            <Typography
              sx={{
                font: "bold 1.3rem Pretendard",
                fontSize: "1.3rem",
                color: "#555",
                textAlign: "left",
                boxSizing: "border-box",
                mb: "10px",
              }}
            >
              결제 내역
            </Typography>
            {billings && <BillingHistoryTable billings={billings} />}
          </div>
        </div>

        <CouponModal open={modalOpened} onclose={handleModal} />
      </div>
    )
  );
};

const CouponModal = (props) => {
  const [cookies] = useCookies();

  const [couponCode, setCouponCode] = useState("");
  const [inputMode, setInputMode] = useState(true);
  // const [inputMode, setInputMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [coupon, setCoupon] = useState(null);

  const handleCouponCode = (e) => {
    setCouponCode(e.target.value);
  };

  const next = () => {
    axios.get("/coupon/checkEventCouponCode", { params: { code: couponCode } }).then((res) => {
      const { success, object } = res.data;
      if (success) {
        setCoupon(object);
        setInputMode(false);
      } else {
        alert("쿠폰 코드가 올바르지 않습니다.");
      }
    });
  };

  const enrollEventCoupon = () => {
    const confirm = window.confirm("이벤트 쿠폰을 등록하시겠습니까?");

    if (confirm) {
      setLoading(true);
      axios.post("/api/enrollEventCoupon", { coupon, auth: cookies.auth }).then((res) => {
        const { success, message } = res.data;

        setTimeout(() => {
          if (success) {
            alert("쿠폰 등록이 완료되었습니다.");
          } else {
            alert("쿠폰 등록에 실패했습니다. 다시 시도해주세요.");
          }

          window.location.replace("/dashboard");
        }, 500);
      });
    }
  };

  return (
    <Modal open={props.open} onClose={props.onclose}>
      {/* <Modal open={true} onClose={props.onclose}> */}
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          outline: "none",
          bgcolor: "#fff",
          borderRadius: "10px",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            bgcolor: "#2c6974",
            color: "#fff",
            textAlign: "center",
            font: "700 18px Pretendard",
            p: "10px",
          }}
        >
          쿠폰 코드 입력
        </Box>

        {inputMode && (
          <Box sx={{ bgcolor: "#ddd", width: "300px", p: "40px 10px 10px 10px" }}>
            <Typography
              sx={{ font: "600 16px Pretendard", mb: "20px", color: "#333", textAlign: "center" }}
            >
              보유하신 쿠폰 코드를 입력해주세요.
            </Typography>

            <Textfield fullWidth size="small" value={couponCode} onChange={handleCouponCode} />

            <Box sx={{ display: "flex", gap: "10px", pt: "30px" }}>
              <Button
                variant="contained"
                fullWidth
                sx={{
                  bgcolor: "#497ea2",
                  font: "600 16px Pretendard",
                  "&:hover": {
                    bgcolor: "#295e82",
                  },
                }}
                onClick={props.onclose}
              >
                취소
              </Button>

              <Button
                variant="contained"
                fullWidth
                sx={{
                  bgcolor: "#dd5641",
                  font: "600 16px Pretendard",
                  "&:hover": {
                    bgcolor: "#bd3621",
                  },
                }}
                onClick={next}
              >
                확인
              </Button>
            </Box>
          </Box>
        )}

        {!inputMode && coupon && (
          <Box
            sx={{
              bgcolor: "#ddd",
              p: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                border: "1px solid #333",
                borderRadius: "5px",
                width: "100%",
                borderBottom: "1px dashed #333",
                p: "10px",
                boxSizing: "border-box",
                bgcolor: "#fff",
              }}
            >
              <Typography
                sx={{
                  textAlign: "center",
                  font: "600 18px Pretendard",
                  color: "#e847ae",
                  mb: "15px",
                }}
              >
                {coupon.explain}
              </Typography>

              <Typography
                sx={{ font: "700 26px Pretendard", color: "#3000ff", textAlign: "center" }}
              >
                {coupon.name}
              </Typography>

              <Typography
                sx={{
                  font: "500 14px Pretendard",
                  color: "#888",
                  wordBreak: "keep-all",
                  mt: "20px",
                }}
              >
                해당 쿠폰은 1회용이며, <br />
                쿠폰 사용 후에는 다시 사용할 수 없습니다.
              </Typography>
            </Box>

            <Box
              sx={{
                border: "1px solid #333",
                borderRadius: "5px",
                width: "100%",
                borderTop: "0px dashed #333",
                p: "10px",
                boxSizing: "border-box",
                bgcolor: loading ? "#c33c3c" : "#fff",
                display: "flex",
                justifyContent: "center",
                gap: "5px",
                alignItems: "center",
                cursor: loading ? "default" : "pointer",
                color: loading ? "#fff" : "#333",

                "&:hover": {
                  bgcolor: "#c33c3c",
                  color: "#fff",
                },
              }}
              onClick={loading ? () => {} : enrollEventCoupon}
            >
              {loading ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                <>
                  <GetAppIcon />

                  <Typography sx={{ font: "600 16px Pretendard" }}>바로 적용하기</Typography>
                </>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

const ChangePaymentInfo = ({ user }) => {
  const { TossPayments } = window;
  const clientKey = "test_ck_BX7zk2yd8y2zLY2bpdEA3x9POLqK";
  const navigate = useNavigate();
  const searchParams = useSearchParams()[0];

  console.log(searchParams.get("from"));

  const [modalOpened, setModalOpened] = useState(false);
  const [acceptList, setAcceptList] = useState([false, false]);
  const [cardNumber, setCardNumber] = useState(null);
  const [cardCompany, setCardCompany] = useState(null);

  const handleModal = () => {
    setModalOpened((prev) => !prev);
  };

  const handleAccpetList = (e) => {
    const list = [...acceptList];
    list[e.currentTarget.id] = !list[e.currentTarget.id];
    setAcceptList(list);
  };

  const changePaymentMethod = () => {
    const depth = window.location.href.split("/");
    const url = depth[0] + "//" + depth[2];
    var tosspayments = TossPayments(clientKey);

    tosspayments
      .requestBillingAuth("카드", {
        customerKey: user._id,
        successUrl:
          url +
          `/callback/tosspayments/cardChange/success${
            searchParams.get("from") ? `?from=${searchParams.get("from")}` : ""
          }`,
        failUrl: url + "/callback/tosspayments/cardChange/fail",
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCheckbox = () => {
    if (acceptList.every((item) => item === true)) {
      setAcceptList([false, false]);
    } else {
      setAcceptList([true, true]);
    }
  };

  useEffect(() => {
    if (!user.paymentInfo) return;

    setCardNumber([
      user.paymentInfo.cardNumber.slice(0, 4),
      user.paymentInfo.cardNumber.slice(4, 8),
      user.paymentInfo.cardNumber.slice(8, 12),
      user.paymentInfo.cardNumber.slice(12, 16),
    ]);

    setCardCompany(user.paymentInfo.cardCompany);
  }, [user]);

  return (
    <div className="DashBoard">
      <Container
        maxWidth="sm"
        disableGutters
        sx={{
          height: "100%",
          textAlign: "left",
          bgcolor: "#fff",
          borderRadius: "10px",
          boxShadow: "0px 0px 10px 0px #0000001a",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          p: "1rem",
          overflow: "auto",
        }}
      >
        <Button
          sx={{
            width: "fit-content",
            "> span": {
              fontFamily: "NotoSansKR-Medium",
            },
            "&:hover": { transform: "scale(1.1)" },
          }}
          onClick={() => navigate("/dashboard/subscribe", { replace: true })}
        >
          <ArrowBackIcon />
          <span>뒤로가기</span>
        </Button>

        <Typography sx={{ font: "bold 22px NotoSansKR-Regular" }}>결제수단 변경하기</Typography>

        <Typography sx={{ font: "18px NotoSansKR-Medium", color: "#ababab" }}>
          기존 선택된 결제수단 정보
        </Typography>

        <Typography
          sx={{ font: "bold 15px NotoSansKR-Medium", color: "#c33c3c", mt: "-1rem", px: "0.5rem" }}
        >
          이용 가능한 카드사 : 신한, 삼성, 롯데, 농협, 우리, 현대, 국민, 하나, BC
        </Typography>

        <Box
          sx={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "1px solid #ddd",
            bgcolor: "#fafafa",
            borderRadius: "10px",
          }}
        >
          <Box
            sx={{
              width: "300px",
              height: "150px",
              borderRadius: "10px",
              bgcolor: cardLogo[cardCompany] ? cardLogo[cardCompany].color : "black",
              p: "10px 20px",
              boxSizing: "border-box",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              "> span": {
                color: "white",
                font: "bold 16px NotoSansKR-Regular",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
                "> span": { color: "white", font: "bold 16px NotoSansKR-Regular" },
                height: "25px",
                width: "100%",
              }}
            >
              {cardCompany && (
                <img
                  src={cardLogo[cardCompany] ? cardLogo[cardCompany].logo : ""}
                  alt={cardCompany}
                  style={{ height: "25px", width: "auto" }}
                />
              )}

              <span>{cardCompany || ""}카드</span>
            </Box>
            <img src={icChipImg} style={{ width: "30px" }} alt="IC Chip" />
            <span>
              {cardNumber && cardNumber.map((num, idx) => (idx === 3 ? num : num + " - "))}
            </span>
          </Box>
        </Box>

        <Box sx={{ mb: "10px" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              borderBottom: "1px solid #eee",
              mb: "10px",
            }}
          >
            <Checkbox
              checked={acceptList.every((item) => item === true)}
              onChange={handleCheckbox}
            />
            <Typography
              sx={{ font: "bold 14px NotoSansKR-Regular", cursor: "pointer" }}
              onClick={handleCheckbox}
            >
              모두 동의하기
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",

              gap: "5px",
              pl: "10px",
              boxSizing: "border-box",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
                <CheckIcon
                  id={0}
                  sx={{
                    fontSize: "20px !important",
                    color: acceptList[0] ? "#0f0" : "#ccc",
                    cursor: "pointer",
                  }}
                  onClick={handleAccpetList}
                />
                <Typography sx={{ font: "13px NotoSansKR-Regular" }}>
                  [필수] 구매조건(결제조건) 확인 및 결제진행 동의, 이용약관 동의
                </Typography>
              </Box>

              <span
                style={{
                  font: "12px NotoSansKR-Regular",
                  color: "#888",
                  borderBottom: "1px solid #888",
                  lineHeight: 1,
                  textAlign: "center",
                  cursor: "pointer",
                }}
                onClick={handleModal}
              >
                보기
              </span>
            </Box>

            <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
              <CheckIcon
                id={1}
                sx={{
                  fontSize: "20px !important",
                  color: acceptList[1] ? "#0f0" : "#ccc",
                  cursor: "pointer",
                }}
                onClick={handleAccpetList}
              />
              <Typography sx={{ font: "13px NotoSansKR-Regular" }}>
                [필수] 별도 해지 요청이 없을 시, 선택한 결제수단으로 자동청구 및 결제 동의
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            border: "1px solid #dddddd",
            p: "10px",
            color: "#888",
            borderRadius: "10px",
            wordBreak: "keep-all",
          }}
        >
          선택하신 결제수단으로 정기결제일에 자동 결제가 이루어집니다. <br />
          결제 수단 변경은 구독 {" > "}다음 결제 정보{" > "}결제 수단 변경하기 버튼으로 변경하실 수
          있습니다.
        </Box>

        <Box sx={{ display: "flex", gap: "1rem" }}>
          <Button
            fullWidth
            variant="contained"
            sx={{
              font: "bold 18px NotoSansKR-Regular",
              bgcolor: "#ababab",
              "&:hover": { bgcolor: "#888" },
            }}
            onClick={() => navigate("/dashboard/subscribe", { replace: true })}
          >
            변경 안할게요
          </Button>
          <Button
            fullWidth
            variant="contained"
            sx={{ font: "bold 18px NotoSansKR-Regular" }}
            disabled={!acceptList.every((item) => item === true)}
            onClick={changePaymentMethod}
          >
            변경할게요
          </Button>
        </Box>
      </Container>
      <TermOfUseModal open={modalOpened} handleModal={handleModal} />
    </div>
  );
};

const Cancel = ({ user }) => {
  const navigate = useNavigate();

  const cancelSubscription = () => {
    axios
      .post("/api/payment/cancelSubscription", null, { params: { userId: user._id } })
      .then((res) => {
        const { success } = res.data;

        if (success) {
          alert("구독이 취소되었습니다.");
        } else {
          alert("구독 취소에 실패했습니다. 다시 시도해주세요.");
        }
        window.location.replace("/dashboard");
      });
  };

  return (
    <div className="DashBoard">
      <Container
        maxWidth="sm"
        disableGutters
        sx={{
          height: "100%",
          textAlign: "left",
          bgcolor: "#fff",
          borderRadius: "10px",
          boxShadow: "0px 0px 10px 0px #0000001a",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          p: "1rem",
          overflow: "auto",
        }}
      >
        <Button
          sx={{
            width: "fit-content",
            "> span": {
              fontFamily: "NotoSansKR-Medium",
            },
            "&:hover": { transform: "scale(1.1)" },
          }}
          onClick={() => navigate("/dashboard/subscribe", { replace: true })}
        >
          <ArrowBackIcon />
          <span>뒤로가기</span>
        </Button>

        <Typography sx={{ font: "bold 22px NotoSansKR-Regular" }}>
          위드플러스 라이브 구독 서비스를 해지하시겠어요?
        </Typography>

        <Typography sx={{ font: "18px NotoSansKR-Medium", color: "#ababab" }}>
          해지 완료 후 해지 취소가 불가능합니다.
        </Typography>

        <Box
          sx={{
            flex: 1,
            border: "1px solid #ddd",
            borderRadius: "10px",
            p: "1rem",
            bgcolor: "#fafafa",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography sx={{ font: "bold 16px NotoSansKR-Regular" }}>해지 유의 사항</Typography>

            <Box
              sx={{
                "> li:before": {
                  position: "absolute",
                  top: "9px",
                  left: 0,
                  transform: "translateY(-50%)",
                  display: "block",
                  width: "3px",
                  height: "3px",
                  borderRadius: "50%",
                  bgcolor: "#888",
                  content: "''",
                },
                "> li": {
                  position: "relative",
                  listStyle: "none",
                  paddingLeft: "15px",
                  marginBottom: "10px",
                  font: "14px NotoSansKR-Regular",
                  color: "#888",
                  wordBreak: "keep-all",
                },
              }}
            >
              <li>취소후에도 구독 기간이 만료되는 날까지 구독 서비스를 이용하실 수 있습니다.</li>
              <li>바로 해지 완료 후 취소 불가하며, 다시 가입해야 상품 구독이 가능합니다.</li>
              <li>
                구독 서비스의 이용내역이 없는 경우 청약(구매 및 결제)일로부터 7일 이내 요청하시면
                전액 환불이 가능합니다.
              </li>
              <li>
                남은 구독 기간이 만료된 이후에도 서비스를 이용할 수 있으나, 제한된 서비스로 이용하실
                수 있습니다.
              </li>
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: "flex", gap: "1rem" }}>
          <Button
            fullWidth
            variant="contained"
            sx={{
              font: "bold 18px NotoSansKR-Regular",
              bgcolor: "#ababab",
              "&:hover": { bgcolor: "#888" },
            }}
            onClick={() => navigate("/dashboard/subscribe", { replace: true })}
          >
            구독 서비스 유지
          </Button>
          <Button
            fullWidth
            variant="contained"
            sx={{ font: "bold 18px NotoSansKR-Regular" }}
            onClick={cancelSubscription}
          >
            구독 서비스 해지
          </Button>
        </Box>
      </Container>
    </div>
  );
};

const ImmediatePayment = ({ user }) => {
  // const { TossPayments } = window;
  // const clientKey = "test_ck_BX7zk2yd8y2zLY2bpdEA3x9POLqK";
  const navigate = useNavigate();

  const [modalOpened, setModalOpened] = useState(false);
  const [acceptList, setAcceptList] = useState([false, false]);
  const [cardNumber, setCardNumber] = useState(null);
  const [cardCompany, setCardCompany] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleModal = () => {
    setModalOpened((prev) => !prev);
  };

  const handleAccpetList = (e) => {
    const list = [...acceptList];
    list[e.currentTarget.id] = !list[e.currentTarget.id];
    setAcceptList(list);
  };

  const paymentImmediately = () => {
    setLoading(true);

    axios.get("/api/payment/paymentImmediately", { params: { userId: user._id } }).then((res) => {
      const data = res.data;

      if (data.success) {
        alert("결제가 완료되었습니다.");
      } else {
        alert(`결제에 실패했습니다. 다시 시도해주세요. \n에러 내용 : ${data.error.message}`);
      }

      window.location.replace("/dashboard");
    });
  };

  const handleCheckbox = () => {
    if (acceptList.every((item) => item === true)) {
      setAcceptList([false, false]);
    } else {
      setAcceptList([true, true]);
    }
  };

  useEffect(() => {
    if (!user.paymentInfo) return;

    setCardNumber([
      user.paymentInfo.cardNumber.slice(0, 4),
      user.paymentInfo.cardNumber.slice(4, 8),
      user.paymentInfo.cardNumber.slice(8, 12),
      user.paymentInfo.cardNumber.slice(12, 16),
    ]);

    setCardCompany(user.paymentInfo.cardCompany);
  }, [user]);

  return (
    <div className="DashBoard">
      <Container
        maxWidth="sm"
        disableGutters
        sx={{
          height: "100%",
          textAlign: "left",
          bgcolor: "#fff",
          borderRadius: "10px",
          boxShadow: "0px 0px 10px 0px #0000001a",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          p: "1rem",
          overflow: "auto",
        }}
      >
        <Button
          sx={{
            width: "fit-content",
            "> span": {
              fontFamily: "NotoSansKR-Medium",
            },
            "&:hover": { transform: "scale(1.1)" },
          }}
          onClick={() => navigate("/dashboard/subscribe", { replace: true })}
        >
          <ArrowBackIcon />
          <span>뒤로가기</span>
        </Button>

        <Typography sx={{ font: "bold 22px NotoSansKR-Regular" }}>
          지금 바로 결제해서 구독 모델을 사용하시겠어요?
        </Typography>

        <Typography sx={{ font: "18px NotoSansKR-Medium", color: "#ababab" }}>
          기존 선택된 결제수단 정보
        </Typography>

        <Box
          sx={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "1px solid #ddd",
            bgcolor: "#fafafa",
            borderRadius: "10px",
          }}
        >
          <Box
            sx={{
              width: "300px",
              height: "150px",
              borderRadius: "10px",
              bgcolor: cardLogo[cardCompany] ? cardLogo[cardCompany].color : "black",
              p: "10px 20px",
              boxSizing: "border-box",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              "> span": {
                color: "white",
                font: "bold 16px NotoSansKR-Regular",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
                "> span": { color: "white", font: "bold 16px NotoSansKR-Regular" },
                height: "25px",
                width: "100%",
              }}
            >
              {cardCompany && (
                <img
                  src={cardLogo[cardCompany] ? cardLogo[cardCompany].logo : ""}
                  alt={cardCompany}
                  style={{ height: "25px", width: "auto" }}
                />
              )}

              <span>{cardCompany || ""}카드</span>
            </Box>
            <img src={icChipImg} style={{ width: "30px" }} alt="IC칩" />
            <span>
              {cardNumber && cardNumber.map((num, idx) => (idx === 3 ? num : num + " - "))}
            </span>
          </Box>
        </Box>

        <Box sx={{ mt: "-10px", display: "flex", justifyContent: "flex-end" }}>
          <Button
            sx={{ textDecoration: "underline", "&:hover": { textDecoration: "underline" } }}
            onClick={() =>
              (window.location.href =
                "/dashboard/subscribe/change-payment-info?from=payment-immediately")
            }
          >
            결제수단을 변경하시겠어요?
          </Button>
        </Box>

        <Box sx={{ mb: "10px" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              borderBottom: "1px solid #eee",
              mb: "10px",
            }}
          >
            <Checkbox
              checked={acceptList.every((item) => item === true)}
              onChange={handleCheckbox}
            />
            <Typography
              sx={{ font: "bold 14px NotoSansKR-Regular", cursor: "pointer" }}
              onClick={handleCheckbox}
            >
              모두 동의하기
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",

              gap: "5px",
              pl: "10px",
              boxSizing: "border-box",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
                <CheckIcon
                  id={0}
                  sx={{
                    fontSize: "20px !important",
                    color: acceptList[0] ? "#0f0" : "#ccc",
                    cursor: "pointer",
                  }}
                  onClick={handleAccpetList}
                />
                <Typography sx={{ font: "13px NotoSansKR-Regular" }}>
                  [필수] 구매조건(결제조건) 확인 및 결제진행 동의, 이용약관 동의
                </Typography>
              </Box>

              <span
                style={{
                  font: "12px NotoSansKR-Regular",
                  color: "#888",
                  borderBottom: "1px solid #888",
                  lineHeight: 1,
                  textAlign: "center",
                  cursor: "pointer",
                }}
                onClick={handleModal}
              >
                보기
              </span>
            </Box>

            <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
              <CheckIcon
                id={1}
                sx={{
                  fontSize: "20px !important",
                  color: acceptList[1] ? "#0f0" : "#ccc",
                  cursor: "pointer",
                }}
                onClick={handleAccpetList}
              />
              <Typography sx={{ font: "13px NotoSansKR-Regular" }}>
                [필수] 별도 해지 요청이 없을 시, 선택한 결제수단으로 자동청구 및 결제 동의
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            border: "1px solid #dddddd",
            p: "10px",
            color: "#888",
            borderRadius: "10px",
            wordBreak: "keep-all",
          }}
        >
          선택하신 결제수단으로 바로 결제가 이루어집니다. <br />
          결제 수단 변경은 구독 {" > "}다음 결제 정보{" > "}결제 수단 변경하기 버튼으로 변경하실 수
          있습니다.
        </Box>

        <Box sx={{ display: "flex", gap: "1rem" }}>
          <Button
            fullWidth
            variant="contained"
            sx={{
              font: "bold 18px NotoSansKR-Regular",
              bgcolor: "#ababab",
              "&:hover": { bgcolor: "#888" },
            }}
            onClick={() => navigate("/dashboard/subscribe", { replace: true })}
          >
            결제 안할게요
          </Button>
          <Button
            fullWidth
            variant="contained"
            sx={{ font: "bold 18px NotoSansKR-Regular" }}
            disabled={!acceptList.every((item) => item === true)}
            onClick={paymentImmediately}
          >
            결제할게요
          </Button>
        </Box>
      </Container>
      <TermOfUseModal open={modalOpened} handleModal={handleModal} />

      <Modal open={loading}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            "&:focus-visible": {
              outline: "none ",
            },
          }}
        >
          <CircularProgress />
        </Box>
      </Modal>
    </div>
  );
};

export default index;
