import { LoadingModal } from "components/Modal";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import io from "socket.io-client";
import {
  IntroView,
  MidResultContainer,
  QuizContainer,
  QuizResultContainer,
  SlideView,
} from "../QuizSlidePage";

const QuizSlideContext = createContext({
  accessCode: "",
});

const QuizSlideProvider = ({ children }) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [socket, setSocket] = useState(null);
  const [isSocketDisconnected, setSocketDisconnected] = useState(false);

  const [slideList, setSlideList] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(<IntroView />);
  const [users, setUsers] = useState([]);
  const [accessCode, setAccessCode] = useState("123456");
  const [playingData, setPlayingData] = useState({});
  const [isAccessCodeOpened, setAccessCodeOpened] = useState(true);
  const [isAccessCodeVisible, setAccessCodeVisible] = useState(true);
  const [currentView, setCurrentView] = useState(<QuizContainer />);
  const [timer, setTimer] = useState(10);

  useEffect(() => {
    if (!socket) {
      setSocket(io.connect("http://localhost:3002"));
    }
  }, []);

  useEffect(() => {
    const preventGoBack = (e) => {
      window.location.href = "/features/quiz";
    };

    (() => {
      window.history.pushState(null, "", window.location.href);
      window.addEventListener("popstate", preventGoBack);
    })();

    return () => {
      window.removeEventListener("popstate", preventGoBack);
    };
  }, []);

  useEffect(() => {
    if (!socket) return;

    socket.on("connect", () => {
      console.log("connected");
      setSocketDisconnected(false);

      socket.emit("joinRoom", { type: "Live Quiz", id: id, admin: true }, (res) => {
        console.log(res);
      });

      if (playingData.isStarted && !playingData.isTimeOver) {
        socket.emit("reloadTimer", { timer: timer, quizId: id });
      }
    });

    socket.on("disconnect", (r) => {
      setSocketDisconnected(true);
      console.log("disconnected");
      console.log(r);
    });

    socket.on("getAccessCode", (data) => {
      setAccessCode(data.code + "");
    });

    socket.on("enterUser", (data) => {
      setUsers([...users, { uid: data.uid, name: data.name, imgIdx: data.imgIdx }]);
    });

    socket.on("updateUsers", (data) => {
      setUsers(data.users);
    });

    socket.on("getUsers", (data) => {
      setUsers(data.users);
    });

    socket.on("startQuiz", (data) => {
      // setAccessCodeOpened(false);

      // setTimeout(() => {
      setPlayingData(data.playingData);
      setSlideList(data.playingData.slideList);
      setCurrentSlide(<SlideView />);
      setTimer(data.playingData.slideList[0].timer);
      // }, 1000);
    });

    socket.on("quizStart", async (data) => {
      setPlayingData(data.quiz.playingData);
    });

    socket.on("submitAnswer", (data) => {
      setPlayingData(data.playingData);
    });

    socket.on("timeOver", (data) => {
      setPlayingData(data.quiz.playingData);
    });

    socket.on("showQuestionAnswer", () => {
      setPlayingData({ ...playingData, isAnswerOpened: true });
    });

    socket.on("showQuestionResult", (data) => {
      setPlayingData({ ...data.quiz.playingData, isResultOpened: true });
      setUsers(data.quiz.playingUsers);
      setCurrentView(<MidResultContainer />);
    });

    socket.on("nextQuiz", (data) => {
      const quiz = data.quiz;

      setPlayingData(quiz.playingData);
      setCurrentView(<QuizContainer />);
      setTimer(quiz.slideList[quiz.playingData.slideIndex].timer);
    });

    socket.on("quizResult", (data) => {
      let quiz = data.quiz;

      setUsers(quiz.playingUsers);
      setPlayingData(quiz.playingData);
      setCurrentView(<QuizResultContainer />);
    });

    socket.on("timer", (data) => {
      setTimer(data.timer);
    });

    return () => {
      socket.off("connect");
      socket.off("disconnect");
      socket.off("getAccessCode");
      socket.off("enterUser");
      socket.off("updateUsers");
      socket.off("getUsers");
      socket.off("startQuiz");
      socket.off("quizStart");
      socket.off("submitAnswer");
      socket.off("timeOver");
      socket.off("showQuestionAnswer");
      socket.off("showQuestionResult");
      socket.off("nextQuiz");
      socket.off("timer");
      // socket.off("getQuizData");
    };
  });

  useEffect(() => {
    if (isAccessCodeOpened) {
      setTimeout(() => {
        setAccessCodeVisible(true);
      }, 1500);
    } else {
      setAccessCodeVisible(false);
    }
  }, [isAccessCodeOpened]);

  useEffect(() => {
    const preventGoBack = (e) => {
      socket.disconnect();
      navigate("/features/quiz", { replace: true });
    };

    // window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", preventGoBack);

    return () => {
      window.removeEventListener("popstate", preventGoBack);
    };
  }, [socket]);

  const value = {
    accessCode,
    users,
    setUsers,
    setAccessCode,
    setSlideList,
    playingData,
    setPlayingData,
    socket,
    isAccessCodeOpened,
    setAccessCodeOpened,
    isAccessCodeVisible,
    currentSlide,
    setCurrentSlide,
    currentView,
    setCurrentView,
    timer,
    setTimer,
  };
  return (
    <QuizSlideContext.Provider value={value}>
      <>
        {children}
        <LoadingModal open={isSocketDisconnected} />
      </>
    </QuizSlideContext.Provider>
  );
};

export { QuizSlideContext, QuizSlideProvider };
