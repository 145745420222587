import React, { createContext, useContext, useEffect, useState } from "react";
import io from "socket.io-client";
import { useNavigate, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";

const UserContext = createContext({
  socket: null,
  slotName: "",
});

const UserProvider = ({ children }) => {
  const { gameId } = useParams();
  const [cookies] = useCookies(["uid"]);

  const [socket, setSocket] = useState(null);
  const [slotName, setSlotName] = useState("");

  useEffect(() => {
    if (!socket) return;

    socket.emit("getSlotName", { gameId });
    socket.emit("joinRoom", { gameId: `${gameId}-${cookies.uid}` });
  }, [socket]);

  useEffect(() => {
    if (!socket) {
      setSocket(io.connect("http://localhost:3005"));
      return;
    }

    socket.on("connect", () => {
      if (!cookies.uid) {
        window.location.reload();
        return;
      }

      socket.emit("joinRoom", { gameId: `${gameId}-${cookies.uid}` });
    });

    socket.on("getSlotName", (data) => {
      setSlotName(data.slotName);
    });

    socket.on("removeUserUnderTest", () => {
      alert("진행자가 테스트를 종료했습니다.");
      window.location.href = "/";
    });

    return () => {
      socket.off("getSlotName");
      socket.off("removeUserUnderTest");
      socket.off("connect");
    };
  });

  const value = {
    socket,
    slotName,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export { UserContext, UserProvider };
